import React from 'react';
import { Layout, Stack, Main } from '@layout';
import PageTitle from '@components/PageTitle';
import Divider from '@components/Divider';
import Seo from '@widgets/Seo';

const PageCookies = (props) => {
  return (
    <Layout {...props}>
      <Seo title='Política de Cookies' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Política de Cookies'
          subheader={`
            Bienvenido a Pesoplus.app. Esta política de cookies describe cómo y por qué utilizamos cookies en nuestro sitio web. Al usar Pesoplus.app, aceptas el uso de cookies de acuerdo con esta política. Si no estás de acuerdo, por favor, no continúes utilizando nuestro sitio web.
            <br><br>
            <strong>¿Qué son las cookies?</strong><br>
            Las cookies son pequeños archivos de texto que se almacenan en tu navegador cuando visitas un sitio web. Se utilizan ampliamente para que los sitios funcionen correctamente y para proporcionar información a los propietarios del sitio.
            <br><br>
            <strong>¿Cómo utilizamos las cookies?</strong><br>
            Utilizamos cookies por varias razones, incluyendo:
            <ul>
              <li>Para proporcionar una experiencia de navegación personalizada.</li>
              <li>Para analizar cómo los usuarios interactúan con nuestro sitio web y mejorar su funcionamiento.</li>
              <li>Para recordar tus preferencias y ajustes durante y entre las visitas.</li>
            </ul>
            <br><br>
            <strong>¿Qué tipos de cookies utilizamos?</strong><br>
            Utilizamos cookies esenciales y no esenciales en nuestro sitio web:
            <ul>
              <li><strong>Cookies esenciales:</strong> Son necesarias para que el sitio funcione correctamente. Estas incluyen, por ejemplo, cookies que te permiten iniciar sesión en áreas seguras de nuestro sitio.</li>
              <li><strong>Cookies no esenciales:</strong> Estas cookies se utilizan para analizar el tráfico y el comportamiento de los usuarios en el sitio web. No se almacenarán en tu navegador a menos que des tu consentimiento explícito.</li>
            </ul>
            <br><br>
            <strong>Control de cookies</strong><br>
            Puedes controlar y gestionar las cookies en tu navegador. La mayoría de los navegadores te permiten rechazar o aceptar cookies, así como eliminar las ya almacenadas. Sin embargo, ten en cuenta que esto podría afectar la funcionalidad de nuestro sitio web.
            <br><br>
            Para más información sobre cómo utilizamos las cookies, contáctanos en info@pesoplus.app.
          `}
        />
      </Stack>
      <Divider />
      <Main>
      </Main>
    </Layout>
  );
};

export default PageCookies;
